//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { assetURL } from '~/plugins/utilities'

export default {
  name: 'ReviewRatingSection',
  components: {
    ThemeImage: () => import('@/components/ThemeImage.vue'),
  },
  computed: {
    ...mapGetters('ui', ['darkMode']),
  },
  methods: {
    assetURL,
  },
}
